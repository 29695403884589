import request from "apiClient";
function list(orgId, queryString) {
  return request({
    url: `/api/${orgId}/bookings${queryString}`,
    method: "GET"
  });
}
function count(orgId, queryString) {
  return request({
    url: `/api/${orgId}/bookings/index_count${queryString}`,
    method: "GET"
  });
}
function get(orgId, bookingCode) {
  return request({
    url: `/api/${orgId}/bookings/${bookingCode}`,
    method: "GET"
  });
}
function getVerificationRequest(orgId, bookingCode) {
  return request({
    url: `/dashboard/qa-bnb-1703262123784/reservations/CZPKHCE`,
    method: "GET"
  });
}
function getById(orgId, bookingId) {
  return request({
    url: `/api/${orgId}/bookings/byId/${bookingId}`,
    method: "GET"
  });
}
function months(orgId) {
  return request({
    url: `/api/${orgId}/bookings/months`,
    method: "GET"
  });
}
function update(orgId, bookingCode, params2) {
  return request({
    url: `/api/${orgId}/bookings/${bookingCode}`,
    method: "PATCH",
    data: params2
  });
}
function simpleUpdate(orgId, bookingId, data) {
  return request({
    url: `/api/${orgId}/bookings/${bookingId}/simple`,
    method: "PATCH",
    data
  });
}
function retrieveCustomer(orgId, bookingId, data) {
  return request({
    url: `/api/${orgId}/bookings/${bookingId}/retrieve_customer`,
    method: "PATCH",
    data
  });
}
function destroy(orgId, bookingId) {
  return request({
    url: `/api/${orgId}/bookings/${bookingId}`,
    method: "DELETE"
  });
}
function deleteSignature(signId) {
  return request({ url: `/signatures/${signId}`, method: "DELETE" });
}
function deletePhoto(photoId) {
  return request({ url: `/id_photos/${photoId}`, method: "DELETE" });
}
function cancel(orgId, bookingCode, cancellationReason, cancellationNotes) {
  return request({
    url: `/api/${orgId}/bookings/cancel?booking_code=${bookingCode}`,
    method: "POST",
    data: {
      cancellation_reason: cancellationReason,
      cancellation_notes: cancellationNotes
    }
  });
}
function getTapechartAvailability(orgId, body) {
  return request({
    url: `/api/${orgId}/unavailable_ranges`,
    method: "POST",
    data: body
  });
}
function tapechartCSV(orgId, startDate, endDate) {
  return request({
    url: `/api/${orgId}/tapechart_csv`,
    method: "PATCH",
    data: {
      start_date: startDate,
      end_date: endDate
    }
  });
}
function confirmInquiry(orgId, bookingCode) {
  return request({
    url: `/api/${orgId}/bookings/confirm`,
    method: "PATCH",
    data: { booking_code: bookingCode }
  });
}
function denyInquiry(orgId, bookingCode) {
  return request({
    url: `/api/${orgId}/bookings/deny`,
    method: "PATCH",
    data: { booking_code: bookingCode }
  });
}
function histories(orgId, bookingCode, { params: params2 }) {
  return request({
    url: `/api/${orgId}/bookings/${bookingCode}/history`,
    method: "GET",
    params: params2
  });
}
function verificationMail(bookingCode) {
  return request({
    url: `/api/v2/my-bookings/verification/${bookingCode}`,
    method: "GET",
    params
  });
}
function createContract(orgId, { data }) {
  return request({
    url: `/api/${orgId}/bookings/generate_new_contract`,
    method: "POST",
    data
  });
}
const BookingService = {
  get,
  getById,
  list,
  update,
  months,
  simpleUpdate,
  cancel,
  destroy,
  getTapechartAvailability,
  tapechartCSV,
  confirmInquiry,
  deletePhoto,
  denyInquiry,
  deleteSignature,
  getVerificationRequest,
  verificationMail,
  count,
  histories,
  createContract,
  retrieveCustomer
};
export default BookingService;
